<template>
  <v-card class="nospace">
    <loading :active.sync="loading" />
    <v-data-table
      hide-actions
      :headers="headers"
      width="100% !important"
      hide-default-footer
      :items="items"
    >
      <template slot="items" slot-scope="props">
        <td style="min-width: 200px !important">
          {{ props.item.actualAmountPaid }} {{ props.item.customerCurrency }}
        </td>
        <td>
          {{ props.item.employeeName }}({{ props.item.employeeId }})<br />
          {{ getTime3(props.item.updatedAt) }}
        </td>
        <td style="min-width: 180px !important">
          {{ props.item.modeOfPayment }}
          <div v-if="props.item.bankName && props.item.modeOfPayment != 'Cash'">
            {{ props.item.bankName }}
          </div>
        </td>

        <td style="min-width: 180px !important">
          {{
            props.item.actualPaidDate && props.item.actualPaidTime
              ? getDate(props.item.actualPaidDate) +
                " " +
                props.item.actualPaidTime
              : "-"
          }}
        </td>
        <td style="min-width: 180px !important">
          <AdvancePaid
            v-if="
              assignDetails.amountPaidToDriver == 0 &&
              assignDetails.VendorWorkingCountry == 'PAK'
            "
            :type="'edit'"
            :assignmentData="listData"
            :editData="props.item"
            :actualamoutPaid="listData.actualAmountPaid"
            :driverCreditDuration="
              assignDetails.driverData
                ? assignDetails.driverData.driverPaymentDuration
                : null
            "
            :invoiceCity="invoicingCity"
            :assignedToTransporter="assignDetails.assignedToTransporter"
            :ownerType="assignDetails.ownerType"
            :assignId="assignDetails ? assignDetails.assignmentId : null"
            :currency="assignDetails.customerCurrency"
            :invoiceCompany="invoiceCompany"
            :additionalCharges="assignDetails.assignDetailsadditionalCharges"
            :totaladvancePaid="assignDetails.totaladvancePaid"
            :totalAdvancePaidArray="assignDetails.totalAdvancePaidAmount || 0"
            :driverPrice="assignDetails.paymentAmount"
          />
          <div
            v-if="
              assignDetails.VendorWorkingCountry == 'UAE' ||
              assignDetails.VendorWorkingCountry == 'KSA'
            "
          >
            <AdvancePaid
              v-if="!assignDetails.isPurchaseInvoiceGenerated"
              :type="'edit'"
              :assignmentData="listData"
              :editData="props.item"
              :actualamoutPaid="listData.actualAmountPaid"
              :driverCreditDuration="
                assignDetails.driverData
                  ? assignDetails.driverData.driverPaymentDuration
                  : null
              "
              :invoiceCity="invoicingCity"
              :assignedToTransporter="assignDetails.assignedToTransporter"
              :ownerType="assignDetails.ownerType"
              :assignId="assignDetails ? assignDetails.assignmentId : null"
              :currency="assignDetails.customerCurrency"
              :invoiceCompany="invoiceCompany"
              :additionalCharges="assignDetails.assignDetailsadditionalCharges"
              :totaladvancePaid="assignDetails.totaladvancePaid"
              :totalAdvancePaidArray="assignDetails.totalAdvancePaidAmount || 0"
              :driverPrice="assignDetails.paymentAmount"
            />
          </div>

          <proof-of-delivery
            v-if="
              props.item.document &&
              props.item.document.length &&
              !props.item.isRetryPossibleForRbFailure
            "
            :deliveryProof="props.item.document"
          />
          <v-btn
            v-if="props.item.isRetryPossibleForRbFailure"
            class="advance-btn-color"
            style="padding: 0px !important"
            flat
            color="white"
            @click="retryAdvanceAmount(props.item._id)"
          >
            Retry</v-btn
          >
          <div
            style="
              color: red;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 10px !important;
            "
            v-if="
              !props.item.isRetryPossibleForRbFailure &&
              props.item.advanceStatus == 'RB_Entry_Failed' &&
              props.item.errorFromRealBooks
            "
          >
            <v-tooltip
              top
              content-class="tooltip-op"
              style="margin: 0 10px 0 0 !important"
            >
              <span
                slot="activator"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: white !important;
                  background: red !important;
                  padding: 3px !important;
                  border-radius: 100% !important;
                  height: 20px !important;
                  width: 20px !important;
                  text-align: center !important;
                "
              >
                i
              </span>
              <span class="tooltip">Connect To Technical team</span>
            </v-tooltip>
            <span> *{{ props.item.errorFromRealBooks }}</span>
          </div>
        </td>
      </template>
    </v-data-table>
    <success-dialog
      :content="x.message"
      :heading="x.heading"
      :show="x.success"
      :onclose="closeMessage"
    />

    <!-- <pagination
        :totalPages="totalPages"
        :pages="this.pages"
        @pageNo="page"
        :url="'/dmsAdmin/advance-list'"
        :callback="callback"
        :componentKey="componentKey"
        :action="`advanceListsub`"
        :payload="payload"
        :type="0"
        v-on:pagination-load="checkLoading"
      /> -->
  </v-card>
</template>
<script>
import Vue from "vue";
import moment from "moment";

import pagination from "@/components/Pagination/pagination";
import editAdjustAmountPopup from "@/components/Accounts/CustomerPayment/CustomerPopups/editAdjustAmountPopup.vue";
import { generateAdvanceAccountsListSub } from "@/constants/datatable-headers.js";
import SuccessDialog from "@/components/Common/SuccessDialog";
import ProofOfDelivery from "@/components/Pop-ups/ProofOfDelivery";
import { adjustStatus } from "@/constants/api-urls.js";
import AdvancePaid from "@/components/Accounts/Popups/AdvancePaid";
import { StorageKeys } from "@/constants/constants.js";
import Loading from "vue-loading-overlay";
import { EventBus } from "../../../event-bus.js";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
export default {
  created() {
    this.fetchHistory();
    this.$eventBus.$on("subpartiallist", (value) => {
      console.log("partail list---------socket ");
      this.$emit("assign-list-refresh2");
      this.fetchHistory();
    });
  },
  components: {
    pagination,
    SuccessDialog,
    editAdjustAmountPopup,
    ProofOfDelivery,
    Loading,
    AdvancePaid,
  },
  props: {
    advanceId: String,
    assignDetails: Object,
    listData: Object,
    invoiceCompany: String,
    invoicingCity: String,
  },
  watch: {},
  data() {
    return {
      workingCountry: null,
      selectedids: [],
      selectednames: [],
      waterMark: "Select a Range",

      dateRange2: null,
      checkBox: true,
      selectedValue: "",
      search: "",
      title: null,
      dialog1: false,
      componentKey: 0,
      count: 0,
      totalPages: 0,

      checkDone: true,
      listApiUrl: adjustStatus,
      headers: generateAdvanceAccountsListSub,
      pagination: {},
      loading: true,

      x: {
        message: "The advance payment has been successfully done",
        loading: true,
        success: false,
        heading: "Advance Payment Update",
        menu: false,

        error: null,
        missingField: null,
      },
      payload: {},
      items: [],
      pages: 1,
    };
  },
  methods: {
    fetchHistory() {
      let url = `/dmsAdmin/advance-list`;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios
        .get(this.constants.apiUrl + url + "/" + this.advanceId, config)
        .then(
          (response) => {
            this.loading = false;
            this.items = response.data.data;

            console.log(response.data.data);
          },
          (error) => {
            // this.x.error = "Failed to Fetch User Details";
          }
        );
    },

    retryAdvanceAmount(id) {
      // console.log(this.assignDetails.assignmentId)
      this.loading = true;

      let url = "/dmsAdmin/advance/retry-rb-payment";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        advanceReceiptId: id,
        action: "generateAdvanceReciept",
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.x.message = response.data.data.message;
          this.x.success = true;
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },

    closeMessage() {
      this.x.success = false;
      this.fetchHistory();
    },
    getTime3(date) {
      return moment(date).format("ll LT");
    },
    getDate(date) {
      return moment(date).format("ll");
    },
    checkLoading(event) {
      if (event) {
        this.$emit("on-load", true);
        this.selectedValue = "";
      } else {
        this.$emit("off-load", false);
        this.selectedValue = "";
      }
    },

    //   callback(res) {
    //     this.items = res.data

    //     this.totalPages = Math.ceil(res.totalData / 5);
    //     this.pagination.rowsPerPage = 5;

    //   },
  },
};
</script>
<style>
.td {
  min-width: 200px;
}
.v-input--selection-controls .v-input__control {
  width: 100%;
}
.openMarked-btn-color {
  background-color: #042f71 !important;
}
.advance-btn-color {
  background-color: #e3471b !important;
  text-transform: capitalize;
  width: 24px !important;
  height: 32px !important;
  border-radius: 5px;
}
</style>
